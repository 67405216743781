<template>
  <div>
    <NavbarMain />
    <img src="@/assets/politicas.jpg" class="img-fluid" alt="..." />
    <div class="container">
      <div class="titulopoliticas">
        <p>Politicas y Normatividades de la Compañía Libertador SA</p>
      </div>
      <div class="politicas">
        <Accordion>
          <AccordionTab header="MODELO DE NEGOCIO">
            <p class="text-politicas">
              <span
                >Nuestro modelo esta sobre el nicho de mercado donde las
                prioridades son la oferta al cliente, el cumplimiento, la
                actitud y aptitud del cliente interno</span
              >
            </p>
          </AccordionTab>
          <AccordionTab header="MISIÓN">
            <p
              style="
                margin-top: 0cm;
                margin-right: 0cm;
                margin-bottom: 8pt;
                margin-left: 0cm;
                line-height: 150%;
                font-size: 15px;
                font-family: 'Calibri', sans-serif;
                text-align: justify;
              "
            >
              <span style="font-family: 'Arial', sans-serif"
                >Brindar una experiencia de servicio efectiva, segura y
                confiable.</span
              >
            </p>
          </AccordionTab>

          <AccordionTab header="VISIÓN">
            <p
              style="
                margin-top: 0cm;
                margin-right: 0cm;
                margin-bottom: 8pt;
                margin-left: 0cm;
                line-height: 150%;
                font-size: 15px;
                font-family: 'Calibri', sans-serif;
                text-align: justify;
              "
            >
              <span style="font-family: 'Arial', sans-serif"
                >Proporcionar al 2025 un servicio competitivo mediante el
                compromiso y calidez de nuestro talento humano.</span
              >
            </p>
          </AccordionTab>

          <AccordionTab header="VALORES CORPORATIVOS">
            <p
              style="
                margin-top: 0cm;
                margin-right: 0cm;
                margin-bottom: 8pt;
                margin-left: 0cm;
                line-height: 150%;
                font-size: 15px;
                font-family: 'Calibri', sans-serif;
                text-align: justify;
              "
            >
              <span style="font-family: 'Arial', sans-serif"
                ><p>
                  <strong>Cumplimiento:</strong> Reconocemos el valor del tiempo
                  para las partes interesadas, por eso es importante ofrecer
                  siempre un servicio oportuno para satisfacer sus necesidades.
                  Cumplimos con las metas asignadas y somos oportunos en la
                  entrega de informaci&oacute;n correcta a los grupos de
                  inter&eacute;s.
                </p>
                <p>
                  <strong>Innovaci&oacute;n y Disponibilidad al Cambio:</strong>
                  Estamos atentos a la innovaci&oacute;n de los procesos,
                  procedimientos y cambios tecnol&oacute;gicos,
                  ajust&aacute;ndonos a la vanguardia del mercado.
                </p>
                <p>
                  <strong>Seguridad:</strong> Nos preocupamos por la seguridad
                  de nuestros clientes internos y externos, disminuyendo
                  posibles riesgos durante el desempe&ntilde;o de los procesos
                  mediante su planeaci&oacute;n, ejecuci&oacute;n, seguimiento y
                  evaluaci&oacute;n, y la prestaci&oacute;n del servicio.
                </p>
                <p>
                  <strong>Calidez Humana:</strong> La ofrecemos a las partes
                  interesadas a trav&eacute;s de la aplicaci&oacute;n de
                  procesos de capacitaci&oacute;n, reconocimiento y mejora
                  continua.
                </p>
                <p>
                  <strong>Integridad:</strong> Las acciones de nuestros
                  colaboradores se fundamentan en criterios &eacute;ticos como:
                  Responsabilidad, Honestidad y Respeto hacia los dem&aacute;s
                  grupos de inter&eacute;s.
                </p></span
              >
            </p>
          </AccordionTab>

          <AccordionTab header="POLÍTICA DE CALIDAD">
            <p
              style="
                margin-top: 0cm;
                margin-right: 0cm;
                margin-bottom: 8pt;
                margin-left: 0cm;
                line-height: 150%;
                font-size: 15px;
                font-family: 'Calibri', sans-serif;
                text-align: justify;
              "
            >
              <span style="font-family: 'Arial', sans-serif"
                >En <strong>COMPA&Ntilde;&Iacute;A LIBERTADOR S.A.</strong> Nos
                comprometemos a prestar el servicio de transporte terrestre en
                la modalidad de pasajeros cumpliendo todos los requisitos
                aplicables al servicio, optimizando los recursos y mejorando
                continuamente el sistema de gesti&oacute;n de la calidad con el
                objetivo de aumentar la satisfacci&oacute;n de nuestros
                clientes.</span
              >
            </p>
          </AccordionTab>

          <AccordionTab header="POLÍTICA SOSTENIBILIDAD">
            <p
              style="
                margin-top: 0cm;
                margin-right: 0cm;
                margin-bottom: 8pt;
                margin-left: 0cm;
                line-height: 150%;
                font-size: 15px;
                font-family: 'Calibri', sans-serif;
                text-align: justify;
              "
            >
              <span style="font-family: 'Arial', sans-serif"
                >Nos comprometemos a controlar y optimizar los recursos
                h&iacute;dricos, energ&eacute;ticos y lograr el aprovechamiento
                de los residuos s&oacute;lidos. A brindar capacitaci&oacute;n a
                nuestros colaboradores en temas ambientales, socioculturales y
                econ&oacute;micos que garanticen minimizar los impactos
                negativos producidos por nuestras actividades; adem&aacute;s
                sensibilizar a las partes interesadas en la conservaci&oacute;n
                y protecci&oacute;n del medio ambiente, entre otros temas
                sostenibles. Apoyar a las comunidades locales promoviendo su
                desarrollo econ&oacute;mico y participar activamente en las
                diferentes actividades sociales, convocadas por diferentes
                organizaciones.</span
              >
            </p>
          </AccordionTab>

          <AccordionTab
            header="POLITICA DE USO DE ELEMENTOS DE PROTECCIÓN PERSONAL"
          >
            <div class="text-politicas">
              <p style="text-align: justify">
                La empresa
                <strong
                  >COMPA&Ntilde;&Iacute;A LIBERTADOR S.A. &ldquo;COLIBERTADOR
                  S.A.&rdquo; </strong
                >se compromete a brindar a todos los trabajadores de la
                Compa&ntilde;&iacute;a los adecuados elementos de
                protecci&oacute;n personal para realizar de forma adecuada y
                segura todas las actividades laborales. Esto se realiza con el
                fin de prevenir la materializaci&oacute;n de accidentes de
                trabajo y enfermedades laborales garantizando est&aacute;ndares
                de seguridad, salud y el bienestar f&iacute;sico y mental de
                todos los trabajadores. Dichos elementos de protecci&oacute;n
                personal ser&aacute;n entregados acorde a los peligros
                existentes en la compa&ntilde;&iacute;a identificados
                previamente en la Matriz de Identificaci&oacute;n de Peligros y
                Valoraci&oacute;n de Riesgos seg&uacute;n la Gu&iacute;a
                t&eacute;cnica colombiana (GTC) 45.
              </p>
              <p>
                Es responsabilidad y compromiso del trabajador, utilizar los
                elementos de protecci&oacute;n personal de forma adecuada y
                mantenerlos y conservarlos en perfecto estado para la correcta
                ejecuci&oacute;n de las actividades de trabajo. De igual forma,
                los trabajadores se comprometen a:
              </p>
              <ul>
                <li>
                  Reportar al jefe inmediato y al departamento de Seguridad y
                  Salud en el Trabajo sobre alg&uacute;n da&ntilde;o o deterioro
                  que presente alg&uacute;n elemento de protecci&oacute;n
                  personal para ser remplazado o reparado.
                </li>
                <li>
                  Verificar antes y despu&eacute;s de la actividad laboral
                  diaria, el perfecto estado de los elementos de
                  protecci&oacute;n personal.
                </li>
                <li>
                  Reportar al jefe inmediato y al Departamento de seguridad y
                  salud en el trabajo el no uso o uso inadecuado de los
                  elementos de protecci&oacute;n personal por parte de los
                  dem&aacute;s trabajadores de la Compa&ntilde;&iacute;a.
                </li>
              </ul>
              <p style="text-align: justify">
                Esta pol&iacute;tica de Uso de Elementos de Protecci&oacute;n
                Personal, ser&aacute; revisada anualmente y se comunicar&aacute;
                a todas las personas que trabajen para
                <strong
                  >COMPA&Ntilde;&Iacute;A LIBERTADOR S.A. &ldquo;COLIBERTADOR
                  S.A.&rdquo; </strong
                >&nbsp;o en nombre de la misma, estando a disposici&oacute;n de
                todo aquel que lo requiera, as&iacute; como para el
                p&uacute;blico en general.<br /><br />
                Las sanciones en caso de incumplir dicha pol&iacute;tica, se
                encuentran contempladas en el Reglamento Interno de Trabajo de
                la Compa&ntilde;&iacute;a.
              </p>
              <p>
                Dada en Santa Marta D.T.C.H. a los 20 d&iacute;as del mes de
                enero del 2025.
              </p>
            </div>
            <!-- <object
              data="https://www.berlinave.com/politics/POLITICA_DE_USO_DE_ELEMENTOS_DE_PROTECCION_PERSONAL.pdf"
              type="application/pdf"
              width="100%"
              height="600px"
            >
              <p>
                Tu navegador no puede mostrar el archivo PDF.
                <a
                  href="https://www.berlinave.com/politics/POLITICA_DE_USO_DE_ELEMENTOS_DE_PROTECCION_PERSONAL.pdf"
                  >Descargar el archivo PDF</a
                >
                en su lugar.
              </p>
            </object> -->
          </AccordionTab>

          <AccordionTab
            header="POLITICA DE NO USO DE EQUIPOS MOVILES DURANTE ACTIVIDADES LABORALES"
          >
            <div class="text-politicas">
              <p style="text-align: justify">
                La
                <strong
                  >COMPA&Ntilde;&Iacute;A LIBERTADOR S. A&nbsp;
                  &ldquo;COLIBERTADOR S.A.&rdquo;</strong
                >, siendo consciente de la alta peligrosidad que representa
                conducir un veh&iacute;culo y usar equipos de
                comunicaci&oacute;n al mismo tiempo, as&iacute; mismo,
                &nbsp;mientras se transita por &aacute;reas administrativas,
                operativas y talleres de la compa&ntilde;&iacute;a o
                contratistas donde ejecutan las actividades diarias y concurren
                veh&iacute;culos y peatones de manera permanente; est&aacute;
                comprometida con la prevenci&oacute;n de accidentes de trabajo y
                teniendo en cuenta los efectos negativos en la seguridad al
                conducir o al estar dentro del veh&iacute;culo en la
                prestaci&oacute;n del servicio y este se encuentre estacionado o
                en marcha, proh&iacute;be expresamente el uso de dispositivos
                m&oacute;viles tales como tel&eacute;fonos inteligentes,
                celulares, mp3 o mp4, reproductores con aud&iacute;fonos,
                ipad&acute;s, tablets, radios de telecomunicaciones, avantel y
                dem&aacute;s equipos que ocasionen la distracci&oacute;n de la
                psiquis del conductor. Lo anterior tambi&eacute;n incluye la
                prohibici&oacute;n de leer o responder mensajes de texto o
                sostener chats.
              </p>
              <p style="text-align: justify">
                Para comunicar cualquier anomal&iacute;a el operador o conductor
                del veh&iacute;culo deber&aacute; verificar la posibilidad de
                detener la marcha aplicando las medidas necesarias de
                prevenci&oacute;n a otros actores de la v&iacute;a como las
                luces estacionarias, conforme lo establece las normas de
                tr&aacute;nsito, asegurando que no se estacione en v&iacute;as
                prohibidas que le puedan ocasionar la imposici&oacute;n de una
                infracci&oacute;n de tr&aacute;nsito o un accidente por
                imprudencia al momento de estacionarse.
              </p>
              <p style="text-align: justify">
                Las sanciones en caso de incumplir dicha pol&iacute;tica se
                encuentran contempladas en el C&oacute;digo Nacional de
                Tr&aacute;nsito y Reglamento Interno de Trabajo.
              </p>
              <p>
                Dada en Santa Marta D.T.C.H. a los 20 d&iacute;as del mes de
                enero de 2025.
              </p>
            </div>
            <!-- <object
              data="https://www.berlinave.com/politics/POLITICA_EQUIPOS_MOVILES_Y_ACTIVIDADES_LABORALES.pdf"
              type="application/pdf"
              width="100%"
              height="600px"
            >
              <p>
                Tu navegador no puede mostrar el archivo PDF.
                <a
                  href="https://www.berlinave.com/politics/POLITICA_EQUIPOS_MOVILES_Y_ACTIVIDADES_LABORALES.pdf"
                  >Descargar el archivo PDF</a
                >
                en su lugar.
              </p>
            </object> -->
          </AccordionTab>

          <AccordionTab header="POLITICA DE REGULACIÓN DE VELOCIDAD">
            <div class="text-politicas">
              <p>
                La
                <strong
                  >COMPA&Ntilde;&Iacute;A LIBERTADOR S.A. &ldquo;COLIBERTADOR
                  S.A.&rdquo;</strong
                >
                siendo cumplidora de la reglamentaci&oacute;n establecida en el
                C&oacute;digo Nacional de Tr&aacute;nsito, Ley 769 de 2002, Ley
                1383 de 2010, y dem&aacute;s normas concordantes, teniendo en
                cuenta que la velocidad representa un aspecto muy importante
                dentro de la Seguridad Vial, establece como velocidades
                m&aacute;ximas permitidas las siguientes:
              </p>
              <p>&nbsp;</p>
              <p>80 KM / H: En v&iacute;as nacionales</p>
              <p>50 KM / H: En v&iacute;as urbanas</p>
              <p>50 KM / H: En v&iacute;as de entrada a cascos urbanos</p>
              <p>30 KM / H: En v&iacute;as rurales sin pavimentar</p>
              <p>
                30 KM / H: En sectores escolares o de alto flujo de peatones
              </p>
              <p>10 KM / H: Locaciones o en zonas donde se realicen obras.</p>
              <p>
                10 KM / H: Circulaci&oacute;n en Rutas internas de la
                Compa&ntilde;&iacute;a
              </p>
              <p>&nbsp;</p>
              <p>
                Estos l&iacute;mites de velocidad deben reducirse en caso de que
                las condiciones clim&aacute;ticas adversas as&iacute; lo
                requieran y est&aacute;n sujetos a lo establecido por las
                autoridades locales del &aacute;rea de influencia de los
                proyectos.
              </p>
              <p>&nbsp;</p>
              <p>
                En todo caso y respecto de los l&iacute;mites de velocidad
                se&ntilde;alados en la presente pol&iacute;tica se
                deber&aacute;n atender de forma prioritaria la
                se&ntilde;alizaci&oacute;n dispuesta en las v&iacute;as para
                estos efectos.
              </p>
              <p>&nbsp;</p>
              <p>
                Para el efectivo control de la misma se utilizar&aacute; el
                seguimiento a infracciones de tr&aacute;nsito por exceso de
                velocidad llevando a cabo un control mensual de la reincidencia
                que permita retroalimentar la presente pol&iacute;tica,
                as&iacute; como el seguimiento mediante la plataforma de GPS de
                las alertas de velocidad en los casos en que los conductores
                excedan los l&iacute;mites establecidos en la presente
                pol&iacute;tica.&nbsp; Las sanciones en caso de incumplir dicha
                pol&iacute;tica, se encuentran contempladas en el C&oacute;digo
                nacional de tr&aacute;nsito y el Reglamento interno de trabajo.
              </p>
              <p>&nbsp;</p>
              <p>
                Dada en Santa Marta D.T.C.H. a los 20 d&iacute;as del mes de
                enero de 2025.
              </p>
            </div>
            <!-- <object
              data="https://www.berlinave.com/politics/POLITICA_DE_REGULACION_DE_VELOCIDAD.pdf"
              type="application/pdf"
              width="100%"
              height="600px"
            >
              <p>
                Tu navegador no puede mostrar el archivo PDF.
                <a
                  href="https://www.berlinave.com/politics/POLITICA_DE_REGULACION_DE_VELOCIDAD.pdf"
                  >Descargar el archivo PDF</a
                >
                en su lugar.
              </p>
            </object> -->
          </AccordionTab>

          <AccordionTab header="POLITICA DE USO DEL CINTURÓN DE SEGURIDAD">
            <div class="text-politicas">
              <p style="text-align: justify">
                La
                <strong
                  >COMPA&Ntilde;&Iacute;A LIBERTADOR S.A.&nbsp;
                  &ldquo;COLIBERTADOR S.A.&rdquo;</strong
                >
                teniendo como fundamento la Resoluci&oacute;n 19200 del 20 de
                diciembre de 2002, por la cual se reglamenta el uso e
                instalaci&oacute;n del cintur&oacute;n de seguridad de acuerdo
                con el art&iacute;culo 82 del C&oacute;digo Nacional de
                Tr&aacute;nsito Terrestre y conforme lo se&ntilde;ala la norma
                todos los veh&iacute;culos automotores que transiten por las
                v&iacute;as del territorio nacional, incluyendo las urbanas,
                deber&aacute;n portar en los asientos delanteros el
                cintur&oacute;n de seguridad, a su vez contempla que el uso del
                cintur&oacute;n de seguridad es obligatorio para todos los
                veh&iacute;culos automotores, a trav&eacute;s de la presente
                pol&iacute;tica se establece la obligatoriedad del uso del
                cintur&oacute;n de seguridad debidamente abrochado para todos
                los conductores y pasajeros en los asientos delanteros y en la
                primera fila de la cabina durante todo el recorrido.
              </p>
              <p style="text-align: justify">
                Es responsabilidad del conductor antes de poner en marcha el
                veh&iacute;culo, dedicar unos segundos a colocarse el
                cintur&oacute;n de seguridad correctamente y hacer la
                recomendaci&oacute;n a los pasajeros que deban usarlo.
              </p>
              <p>Se recomienda al conductor tener en cuenta que:</p>
              <ul>
                <li>
                  La parte de abajo del cintur&oacute;n siempre debe quedar por
                  encima de los muslos y nunca en el abdomen.<br /><br />
                </li>
                <li>
                  Ajustar la altura del anclaje superior del cintur&oacute;n
                  para que no sea demasiado elevada ni excesivamente baja, lo
                  correcto es situarlo de tal manera que el cintur&oacute;n
                  quede apoyado ligeramente sobre el hombro y bien centrado.
                </li>
              </ul>
              <p>
                Las sanciones en caso de incumplir dicha pol&iacute;tica, se
                encuentran contempladas en el C&oacute;digo nacional de
                tr&aacute;nsito y en el Reglamento Interno de trabajo de la
                Compa&ntilde;&iacute;a.
              </p>
              <p>
                Dada en Santa Marta D.T.C.H. a los 20 d&iacute;as del mes de
                enero del 2025.
              </p>
            </div>
            <!-- <object
              data="https://www.berlinave.com/politics/POLITICA_USO_DE_CINTURON_DE_SEGURIDAD.pdf"
              type="application/pdf"
              width="100%"
              height="600px"
            >
              <p>
                Tu navegador no puede mostrar el archivo PDF.
                <a
                  href="https://www.berlinave.com/politics/POLITICA_USO_DE_CINTURON_DE_SEGURIDAD.pdf"
                  >Descargar el archivo PDF</a
                >
                en su lugar.
              </p>
            </object> -->
          </AccordionTab>

          <AccordionTab
            header="POLITICA DE REGULACION DE HORAS DE CONDUCCION Y DESCANSO"
          >
            <div class="text-politicas">
              <p style="text-align: justify">
                La
                <strong
                  >COMPA&Ntilde;&Iacute;A LIBERTADOR S.A.&nbsp;
                  &ldquo;COLIBERTADOR S.A.&rdquo;</strong
                >, como objetivo fundamental dentro de su pol&iacute;tica de
                seguridad vial, contempla la importancia de prevenir la fatiga y
                cansancio a la cual pueden verse expuestos los conductores de la
                compa&ntilde;&iacute;a y que pueden derivar en falta de
                concentraci&oacute;n e incluso somnolencia y como consecuencia
                de ello incidentes o accidentes en la v&iacute;a, es por ello
                que se establece como pol&iacute;tica, que desde la Alta
                Gerencia la regulaci&oacute;n del tiempo que un conductor opere
                diariamente el veh&iacute;culo asignado como herramienta de
                trabajo.
              </p>
              <ul>
                <li>
                  Al t&eacute;rmino de la jornada laboral, que ser&aacute; de 8
                  horas m&aacute;ximo de conducci&oacute;n, se exigir&aacute; al
                  conductor un periodo m&iacute;nimo de descanso de 8 horas
                  antes de volver a iniciar su jornada de conducci&oacute;n.<br /><br />
                </li>
                <li>
                  Es pol&iacute;tica de la Compa&ntilde;&iacute;a otorgar los
                  d&iacute;as de descanso conforme a las disposiciones legales
                  contenidas en el C&oacute;digo sustantivo del trabajo.
                </li>
              </ul>
              <p style="text-align: justify">
                Esta pol&iacute;tica de regulaci&oacute;n del comportamiento
                humano en seguridad vial, ser&aacute; revisada anualmente y se
                comunicar&aacute; a todas las personas que trabajen para
                <strong
                  >COMPA&Ntilde;&Iacute;A LIBERTADOR S.A. &ldquo;COLIBERTADOR
                  S.A.&rdquo; </strong
                >o en nombre de la misma, estando a disposici&oacute;n del
                p&uacute;blico en general y dem&aacute;s partes interesadas.
              </p>
              <p>
                Dada en Santa Marta D.T.C.H. a los 20 d&iacute;as del mes de
                enero del 2025.
              </p>
            </div>
            <!-- <object
              data="https://www.berlinave.com/politics/POLITICA_DE_REGULACION_DE_HORAS_DE_CONDUCCION_Y_DESCANSO.pdf"
              type="application/pdf"
              width="100%"
              height="600px"
            >
              <p>
                Tu navegador no puede mostrar el archivo PDF.
                <a
                  href="https://www.berlinave.com/politics/POLITICA_DE_REGULACION_DE_HORAS_DE_CONDUCCION_Y_DESCANSO.pdf"
                  >Descargar el archivo PDF</a
                >
                en su lugar.
              </p>
            </object> -->
          </AccordionTab>

          <AccordionTab
            header="POLITICA DE NO CONSUMO DE ALCOHOL, TABACO Y SUSTANCIAS PSICOACTIVAS"
          >
            <div class="text-politicas">
              <p style="text-align: justify">
                En la
                <strong
                  >COMPA&Ntilde;IA LIBERTADOR S.A. &ldquo;COLIBERTADOR
                  S.A.&rdquo;</strong
                >, somos conscientes de que el uso de alcohol, las sustancias
                psicoactivas y cigarrillos productos de tabaco, sus derivados,
                suced&aacute;neos o imitadores y los dispositivos necesarios
                para su funcionamiento; entre los que se encuentran los Sistemas
                Electr&oacute;nicos de Administraci&oacute;n de Nicotina (SEAN),
                y Sistemas Similares Sin Nicotina (SSSN), los Productos de
                Tabaco Calentado (PTC) y Productos de Nicotina Oral (PNO), entre
                otros, causan efectos adversos en la salud, afecta la seguridad
                de los actores de la v&iacute;a, as&iacute; como la eficiencia y
                productividad de los empleados.
              </p>
              <p style="text-align: justify">
                Es pol&iacute;tica de
                <strong
                  >COMPA&Ntilde;IA LIBERTADOR S.A. &ldquo;COLIBERTADOR
                  S.A.&rdquo;</strong
                >, prohibir el consumo, uso, fabricaci&oacute;n,
                distribuci&oacute;n, venta, transporte, promoci&oacute;n,
                ocultamiento, transferencia o almacenamiento de sustancias no
                autorizadas, prohibidas, ilegales o controladas, inhalantes y
                bebidas embriagantes (alcohol y drogas) a sus empleados,
                proveedores, contratistas, subcontratistas y visitantes. De
                igual forma la Compa&ntilde;&iacute;a proh&iacute;be:
              </p>
              <ul>
                <li>
                  El consumo de sustancias psicoactivas, tabaco u alcohol en
                  &aacute;reas donde se desarrollen actividades directas de la
                  empresa, sean instalaciones propias o contratadas, como
                  tambi&eacute;n al interior de los veh&iacute;culos previo,
                  durante y posterior a su conducci&oacute;n. As&iacute; como
                  estar bajo los efectos de este tipo de sustancia.
                </li>
                <li>
                  Conducir alg&uacute;n tipo de veh&iacute;culo que tengan
                  relaci&oacute;n directa o indirecta con la empresa, posterior
                  a haber consumido bebidas alcoh&oacute;licas, sustancias
                  psicoactivas u otras que alteren o afecten el entendimiento,
                  la atenci&oacute;n y los reflejos, as&iacute; como al momento
                  de estar bajo sus efectos.
                </li>
              </ul>
              <p style="text-align: justify">
                Debido a que el consumo de este tipo de sustancias constituye un
                factor de riesgo que afecta la integridad de los trabajadores y
                la vida de los usuarios que utilizan nuestros servicios, la
                <strong
                  >COMPA&Ntilde;IA LIBERTADOR S.A. &ldquo;COLIBERTADOR
                  S.A.&rdquo;</strong
                >
                establece que se realizar&aacute;n pruebas de drogas
                peri&oacute;dicas de manera aleatoria al personal. Igualmente
                establece como pol&iacute;tica y en cumplimiento de las
                disposiciones que reglamentan la actividad transportadora y de
                uso de los terminales de transporte, realizar diariamente
                pruebas de alcoholimetr&iacute;a previa a cada despacho de forma
                permanente.
              </p>
              <p style="text-align: justify">
                Para asegurarnos que se cumpla la presente pol&iacute;tica,
                estas pruebas, en cumplimiento de la ley, se realizaran con
                personal id&oacute;neo entendi&eacute;ndose por este un
                m&eacute;dico laboral, el cual debe poseer los equipos de
                medici&oacute;n debidamente avalados y certificados por el
                Invima, adoptando los protocolos de medici&oacute;n establecidos
                por el fabricante.
              </p>
              <p style="text-align: justify">
                Los trabajadores de la
                <strong
                  >COMPA&Ntilde;IA LIBERTADOR S.A. &ldquo;COLIBERTADOR
                  S.A.&rdquo;</strong
                >
                sin importar su rango o posici&oacute;n no pueden presentarse a
                laborar bajo efecto de SPA (sustancias psicoactivas), alcohol
                y/o tabaco que alteren el estado de la conciencia, estado de
                &aacute;nimo, percepci&oacute;n y capacidad de reacci&oacute;n.
              </p>
              <p>
                Por consiguiente, se establecieron las siguientes
                pol&iacute;ticas de autocontrol:
              </p>
              <ul>
                <li>
                  La prueba de alcoholimetr&iacute;a en aire expirado debe ser
                  negativa cuando su valor es 0 mg.
                </li>
                <li>La tolerancia de alcohol en sangre es 0.</li>
                <li>
                  La prueba de sustancias psicoactivas deber&aacute; ser
                  negativa.
                </li>
                <li>
                  Las sanciones en caso de salir positiva algunas de las
                  pruebas, se encuentran descritas en el Reglamento Interno de
                  Trabajo.
                </li>
              </ul>
              <p style="text-align: justify">
                Lo anterior mencionado, constituye una seria e intolerable
                amenaza a la seguridad de las operaciones de la
                Compa&ntilde;&iacute;a y de las personas que las ejecutan como
                nuestros empleados, contratistas, subcontratistas y proveedores.
                Esta prohibici&oacute;n se hace extensiva a los viajes
                v&iacute;a terrestre y a&eacute;rea cuando &eacute;stos son
                pagados o reembolsados por la Empresa o se realicen en
                veh&iacute;culos de la Compa&ntilde;&iacute;a.
              </p>
              <p>
                &ldquo;En
                <strong
                  >COMPA&Ntilde;IA LIBERTADOR S.A. &ldquo;COLIBERTADOR
                  S.A.&rdquo;</strong
                >, trabajamos con nuestros sentidos libres de alcohol y
                drogas&rdquo;
              </p>
              <p>
                Dada en Santa Marta D.T.C.H a los 20 d&iacute;as del mes de
                enero de 2025.
              </p>
            </div>
            <!-- <object
              data="https://www.berlinave.com/politics/POLITICA_NO_CONSUMO_SUSTANCIAS_NOCIVAS.pdf"
              type="application/pdf"
              width="100%"
              height="600px"
            >
              <p>
                Tu navegador no puede mostrar el archivo PDF.
                <a
                  href="https://www.berlinave.com/politics/POLITICA_NO_CONSUMO_SUSTANCIAS_NOCIVAS.pdf"
                  >Descargar el archivo PDF</a
                >
                en su lugar.
              </p>
            </object> -->
          </AccordionTab>

          <AccordionTab header="POLÍTICA DE SEGURIDAD Y SALUD EN EL TRABAJO">
            <div class="text-politicas">
              <p style="text-align: justify">
                La
                <strong
                  >COMPA&Ntilde;&Iacute;A LIBERTADOR S.A. &ldquo;COLIBERTADOR
                  S.A.&rdquo; </strong
                >se compromete a ofrecer el servicio p&uacute;blico de
                transporte terrestre automotor de pasajeros por carretera
                m&aacute;s seguro, c&oacute;modo y puntual de la regi&oacute;n
                Caribe, est&aacute; comprometida hacia la implementaci&oacute;n
                del sistema de gesti&oacute;n de seguridad y salud en el
                trabajo, de acuerdo con la naturaleza propia de los riesgos y
                sus oportunidades para SST para lo cual establece:
              </p>
              <ul>
                <li>
                  Identificar los peligros, evaluar y valorar los riesgos y
                  establecer los respectivos controles para eliminar los
                  peligros y reducir los riesgos laborales y viales, protegiendo
                  la seguridad y salud de todos los trabajadores y manteniendo
                  altos est&aacute;ndares de seguridad y salud en el trabajo.
                </li>
                <li>
                  Proporcionar condiciones de trabajo seguras y saludables para
                  la prevenci&oacute;n de lesiones y deterioro de la salud
                  relacionada con el trabajo, prevenir accidentes de trabajo y
                  enfermedades laborales.
                </li>
                <li>
                  Garantizar la consulta y la participaci&oacute;n de los
                  trabajadores y sus representantes.
                </li>
              </ul>
              <p style="text-align: justify">
                Para lograrlo,
                <strong
                  >COMPA&Ntilde;&Iacute;A LIBERTADOR S.A. &ldquo;COLIBERTADOR
                  S.A.&rdquo;</strong
                >, destina los recursos necesarios a nivel econ&oacute;mico,
                tecnol&oacute;gico y del talento humano, cumple con la
                legislaci&oacute;n vigente y aplicable a las actividades propias
                de la empresa y en materia de seguridad y salud y las suscritas
                de orden contractual y de otra &iacute;ndole, garantiza la
                mejora continua del sistema de gesti&oacute;n de seguridad y
                salud en el trabajo.
              </p>
              <p>
                Dada en Santa Marta D.T.C.H., a los 20 d&iacute;as del mes de
                enero de 2025.
              </p>
            </div>
            <!-- <object
              data="https://www.berlinave.com/politics/POLITICA_DE_SEGURIDAD_Y_SALUD_EN_EL_TRABAJO.pdf"
              type="application/pdf"
              width="100%"
              height="600px"
            >
              <p>
                Tu navegador no puede mostrar el archivo PDF.
                <a
                  href="https://www.berlinave.com/politics/POLITICA_DE_SEGURIDAD_Y_SALUD_EN_EL_TRABAJO.pdf"
                  >Descargar el archivo PDF</a
                >
                en su lugar.
              </p>
            </object> -->
          </AccordionTab>
          <AccordionTab header="POLITICA DE SEGURIDAD VIAL">
            <div class="text-politicas">
              <p style="text-align: justify">
                La&nbsp; <strong>COMPA&Ntilde;&Iacute;A LIBERTADOR S.A</strong
                ><strong>. &ldquo;COLIBERTADOR S.A.&rdquo;,</strong> empresa
                legalmente habilitada para la prestaci&oacute;n del servicio
                p&uacute;blico de transporte terrestre automotor de pasajeros
                por carretera, asume la responsabilidad compartida de
                implementar entre sus colaboradores y partes interesadas&nbsp;
                programas de promoci&oacute;n de h&aacute;bitos y
                comportamientos seguros orientados a la prevenci&oacute;n,
                control y mitigaci&oacute;n de los factores de riesgo
                causantes&nbsp; de siniestros viales en el desarrollo de su fin
                misional y/o en los desplazamientos desde y hacia el sitio de
                trabajo,&nbsp; priorizando el auto cuidado y la conciencia
                social del derecho de los actores viales al disfrute seguro de
                la v&iacute;a, con la convicci&oacute;n de que son inadmisibles
                las muertes y lesiones graves en el desplazamiento vial, para
                ello se asegura de seleccionar y capacitar a los conductores
                misionales y personal administrativo en el cumplimiento de
                la&nbsp; normatividad&nbsp; de tr&aacute;nsito y transporte,
                certificar la&nbsp; competencia laboral y ciudadana con
                &eacute;nfasis en la seguridad vial, comprometi&eacute;ndose a:
              </p>
              <ul>
                <li>
                  Gestionar y promover la regulaci&oacute;n de la velocidad de
                  manera segura a trav&eacute;s incentivos, monitoreo satelital
                  y seguimiento a los comportamientos viales.&nbsp;
                </li>
                <li>
                  Planificar los despachos de forma que se mitigue el riesgo de
                  fatiga.
                </li>
                <li>
                  Controlar el uso de elementos de distracci&oacute;n durante la
                  jornada de trabajo.&nbsp;
                </li>
                <li>
                  implementar programas de cero tolerancias al consumo de
                  alcohol, tabaco y sustancias psicoactivas.
                </li>
                <li>
                  Promover el respeto y la protecci&oacute;n de los actores
                  viales vulnerables (peatones, pasajeros, ciclistas y
                  motociclistas).&nbsp;
                </li>
                <li>
                  Proporcionar los recursos necesarios para el alistamiento, el
                  mantenimiento preventivo y correctivo de los veh&iacute;culos,
                  conservar y mantener disponibles las fichas t&eacute;cnicas de
                  mantenimiento.
                </li>
                <li>
                  Identificar las condiciones de riesgo de&nbsp;la movilidad en
                  las v&iacute;as internas y externas&nbsp;a trav&eacute;s de
                  una infraestructura segura e&nbsp;implementar y&nbsp;
                </li>
                <li>
                  Activar los planes de emergencia viales mediante protocolos de
                  atenci&oacute;n a las v&iacute;ctimas como primer
                  respondiente.
                </li>
              </ul>
              <p style="text-align: justify">
                En&nbsp;cumplimiento a las disposiciones legales y
                reglamentarias contenidas en la Ley 1503 de 2011, las normas
                concordantes y&nbsp;los requisitos pactados;
                mediante&nbsp;procesos orientados al auto control y&nbsp;a la
                mejora continua.
              </p>
              <p style="text-align: justify">
                Esta pol&iacute;tica de seguridad vial ser&aacute; revisada
                anualmente y se comunicar&aacute; a todas las personas que
                trabajen para
                <strong
                  >COMPA&Ntilde;&Iacute;A LIBERTADOR S.A. &ldquo;COLIBERTADOR
                  S.A.&rdquo; </strong
                >o en nombre de la misma, estando a disposici&oacute;n del
                p&uacute;blico en general y dem&aacute;s partes interesadas.
              </p>
              <p>
                <strong
                  >&ldquo;En la COMPA&Ntilde;&Iacute;A LIBERTADOR S.A.,</strong
                >
              </p>
              <p>
                <strong
                  >&iexcl;T&uacute; seguridad, Mi movilidad!&rdquo;</strong
                >
              </p>
              <p>
                Dada en Santa Marta D.T.C.H. a los 20 d&iacute;as del mes de
                enero del 2025.
              </p>
            </div>
            <!-- <object
              data="https://www.berlinave.com/politics/POLITICA_DE_SEGURIDAD_VIAL.pdf"
              type="application/pdf"
              width="100%"
              height="600px"
            >
              <p>
                Tu navegador no puede mostrar el archivo PDF.
                <a
                  href="https://www.berlinave.com/politics/POLITICA_DE_SEGURIDAD_VIAL.pdf"
                  >Descargar el archivo PDF</a
                >
                en su lugar.
              </p>
            </object> -->
          </AccordionTab>

          <AccordionTab header="POLÍTICA PREVENTIVA CONTRA EL ACOSO LABORAL">
            <div class="text-politicas">
              <p style="text-align: justify">
                La
                <strong>COMPA&Ntilde;&Iacute;A LIBERTADOR S.A,&nbsp;</strong
                >comprometida con la creaci&oacute;n de un ambiente de trabajo
                sano, seguro y respetuoso, donde todas las personas se sientan
                valoradas y respetadas, establece la presente pol&iacute;tica
                con el objetivo de prevenir y erradicar cualquier forma de
                acoso, incluyendo el acoso sexual, en el &aacute;mbito laboral.
              </p>
              <p>Por ello fija los siguientes puntos:</p>
              <ul>
                <li>
                  Todos los empleados tienen derecho de trabajar en un entorno
                  libre de toda forma de discriminaci&oacute;n y conductas que
                  se puedan considerar hostigamiento, limitaci&oacute;n o
                  alteraci&oacute;n.
                </li>
                <li>
                  Prevenir y erradicar cualquier forma de acoso, incluyendo el
                  acoso sexual, antes de que ocurra.
                </li>
                <li>
                  Garantizar un entorno laboral libre de violencia y
                  discriminaci&oacute;n.
                </li>
                <li>
                  Proteger los derechos de todas las personas que trabajan en la
                  empresa.
                </li>
                <li>
                  Cualquier persona que se comprometa en un comportamiento de
                  hostigamiento ser&aacute; sujeta a investigaci&oacute;n de
                  acuerdo a lo establecido en la normatividad vigente,
                  procedimientos y sus respectivas medidas disciplinarias.
                </li>
              </ul>
              <p style="text-align: justify">
                Esta Pol&iacute;tica ser&aacute; publicada, divulgada y
                actualizada cada vez que la organizaci&oacute;n lo considere
                pertinente. De igual manera estar&aacute; disponible a todas las
                partes interesadas.
              </p>
              <p>
                Dada en Santa Marta D.T.C.H. a los 20 d&iacute;as del mes de
                enero del 2025.
              </p>
            </div>
            <!-- <object
              data="https://www.berlinave.com/politics/POLITICA_PREVENTIVA_CONTRA_EL_ACOSO_LABORAL.pdf"
              type="application/pdf"
              width="100%"
              height="600px"
            >
              <p>
                Tu navegador no puede mostrar el archivo PDF.
                <a
                  href="https://www.berlinave.com/politics/POLITICA_PREVENTIVA_CONTRA_EL_ACOSO_LABORAL.pdf"
                  >Descargar el archivo PDF</a
                >
                en su lugar.
              </p>
            </object> -->
          </AccordionTab>

          <AccordionTab
            header="POLÍTICA DE PROTECCIÓN DEL ACTOR VIAL VULNERABLE"
          >
            <div class="text-politicas">
              <p style="text-align: justify">
                La
                <strong
                  >COMPA&Ntilde;&Iacute;A LIBERTADOR S.A. &ldquo;COLIBERTADOR
                  S.A.&rdquo;</strong
                >, se compromete desde la Alta Direcci&oacute;n a
                dise&ntilde;ar&nbsp; acciones&nbsp; de promoci&oacute;n y
                prevenci&oacute;n con&nbsp; enfoque&nbsp; a la protecci&oacute;n
                de&nbsp; los actores viables m&aacute;s vulnerables como
                peatones, ciclistas y motociclistas; fijando como&nbsp;
                prioridad el desarrollo de estrategias con el fin de disminuir
                la probabilidad de ocurrencia de accidentes que puedan afectar
                su integridad f&iacute;sica, de esta manera preservar la vida,
                socializando entre los dem&aacute;s actores viales&nbsp; que
                conducir de manera segura implica el derecho y el deber de
                proteger la vida de los dem&aacute;s y as&iacute; contribuir a
                la reducci&oacute;n de los &iacute;ndices de accidentes viales,
                convocando a la corresponsabilidad en las v&iacute;as y la
                protecci&oacute;n en todo momento del peat&oacute;n en
                consideraci&oacute;n a que es el &uacute;nico actor Vial que no
                tiene una barrera de defensa distinta a su propio&nbsp; cuerpo
                lo cual aumenta el riesgo de lesiones por causa de accidente.
              </p>
              <p style="text-align: justify">
                Por su parte, sensibilizar a los motociclistas y ciclistas del
                deber de respetar las se&ntilde;ales de tr&aacute;nsito,
                conservar los l&iacute;mites de velocidad, utilizaci&oacute;n
                adecuada de la calzada, carriles exclusivos y&nbsp; zonas
                establecidas para el tr&aacute;nsito,&nbsp; as&iacute;&nbsp;
                como el llamado a los peatones&nbsp; a no poner en riesgo la
                vida en las v&iacute;as haci&eacute;ndose siempre visible,&nbsp;
                transitando por zonas demarcadas, haciendo uso de la
                infraestructura vial&nbsp; tales como&nbsp; puentes
                peatonales,&nbsp; y desarrollando comportamientos seguros en su
                desplazamiento como evitar el uso de celular mientras camina.
              </p>
              <p style="text-align: justify">
                Esta pol&iacute;tica de seguridad vial ser&aacute; revisada
                anualmente y se comunicar&aacute; a todas las personas que
                trabajen para
                <strong
                  >COMPA&Ntilde;&Iacute;A LIBERTADOR S.A. &ldquo;COLIBERTADOR
                  S.A.&rdquo; </strong
                >o en nombre de la misma, estando a disposici&oacute;n del
                p&uacute;blico en general y dem&aacute;s partes interesadas.
              </p>
              <p>
                Dada en Santa Marta D.T.C.H. a los 20 d&iacute;as del mes de
                enero del 2025.
              </p>
            </div>

            <!-- <object
              data="https://www.berlinave.com/politics/POLITICA_DE_PROTECCION_DEL_AUTOR_VIAL.pdf"
              type="application/pdf"
              width="100%"
              height="600px"
            >
              <p>
                Tu navegador no puede mostrar el archivo PDF.
                <a
                  href="https://www.berlinave.com/politics/POLITICA_DE_PROTECCION_DEL_AUTOR_VIAL.pdf"
                  >Descargar el archivo PDF</a
                >
                en su lugar.
              </p>
            </object> -->
          </AccordionTab>

          <AccordionTab header="POLITICA DE TRATAMIENTO DE DATOS PERSONALES">
            <a href="http://www.berlinave.com/ProteccinDatosPersonales.pdf"
              >Clic para descargar políticas de proteción de datos personales</a
            >
          </AccordionTab>
          <AccordionTab
            header="PUNTOS ESTRATÉGICOS PARA ABORDAJE Y CONTROL DE PASAJEROS"
          >
            <a
              href="http://www.berlinave.com/puntos_estrategicos_para_abordaje.pdf"
              >Clic para descargar puntos de abordaje y control</a
            >
          </AccordionTab>
          <AccordionTab header="CONTRATO DE TRANSPORTE">
            <a href="http://www.berlinave.com/ContratoDeTransporte.pdf"
              >Clic para descargar contrato de transporte</a
            >
          </AccordionTab>

          <AccordionTab
            header="FORMALUARIO TRANSPORTE DE NIÑOS, NIÑAS Y ADOLESCENTES"
          >
            <a href="http://www.berlinave.com/AutorizacionTransporteMenores.pdf"
              >Clic para descargar el formulario de transporte de menores</a
            >
          </AccordionTab>
        </Accordion>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";

//import Dropdown from "primevue/dropdown";

//import InputText from "primevue/inputtext";
/*
import Dropdown from "primevue/dropdown";
import AutoComplete from "primevue/autocomplete";
import Calendar from "primevue/calendar";
import CascadeSelect from "primevue/cascadeselect";
*/
import NavbarMain from "@/components/NavbarMain.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {
      showProgressRutas: false,
      origenes: [],
      rutas: [],
      destinos: [],
      rutaorigen: 0,
      rutadestino: 0,
    };
  },

  created: function () {},

  computed: {},

  methods: {},

  components: {
    NavbarMain,
    Footer,
    Accordion,
    AccordionTab,
  },
};
</script>

<style scoped>
.titulopoliticas p {
  color: #004d27;
  font-weight: bold;
  font-size: 25px;
  text-align: center;
  margin-top: 10px;
}

.politicas {
  margin-top: 20px;
}

.btn-berlinave {
  background-color: #004d27 !important;
  color: white !important;
}

.text-politicas {
  margin-top: 0cm;
  margin-right: 0cm;
  margin-bottom: 8pt;
  margin-left: 0cm;
  line-height: 150%;
  font-size: 15px;
  font-family: "Calibri", sans-serif;
  text-align: start;
}

.text-politicas > span {
  font-family: "Arial", sans-serif;
}
</style>
